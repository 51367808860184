<template>
  <router-view />
</template>

<script>
import { onMounted } from 'vue'
export default {
  name: 'App',
  components: { },
  setup() {
    onMounted(() => {
      document.body.style.setProperty('--el-color-primary', '#FB084F');
      document.body.style.setProperty('--el-color-primary-light-9', '#F5FBF0');
      document.body.style.setProperty('--el-color-primary-light-3', '#95d475');
    })
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
}
</style>
