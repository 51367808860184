<!--  -->
<template>
  <el-row type="flex" align="middle">
    <el-col :span="12">
      <img class="leftLogo" :src="require('../assets/HeaderLogo@2x.png')" alt="">
    </el-col>
    <el-col :span="12">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="首页" name="home"></el-tab-pane>
        <el-tab-pane label="关于我们" name="aboutus"></el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
</template>

<script steup>
  import {onMounted, ref} from 'vue'
  import {useRouter,useRoute} from 'vue-router'

  export default {
    name: 'Header',
    setup() {
      const router = useRouter();
      const route  = useRoute()
      let currentPath = route.path
      const activeName = ref(currentPath.replace("/",""))
      function handleClick( tab, event ) {
        router.push(tab.props.name)
      }
      return {
        handleClick,
        activeName
      }
    }
  }
</script>

<style scoped lang='scss'>
  .el-col {
    img {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 20%;
      width: 50%;
      max-width: 390px;
    }
  }

  .demo-tabs {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: right;
    height: 100%;
    padding-right: 45%;

    .el-tabs__content {
      padding: 32px;
      color: #6b778c;
      font-size: 32px;
      font-weight: 600;
    }

    :deep(.el-tabs__nav-wrap)::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0%;
      height: 0px;
      background-color: #fff;
      z-index: 1;
    }

    :deep(.el-tabs__item) {
      font-size: 1.25vw;
      @media screen and (max-width:400px) {
        font-size: 8px;
      }
      @media screen and (min-width:1920px) {
        font-size: 18px;
      }


    }

  }
</style>