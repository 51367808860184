<!--  -->
<template>
   <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-main>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path"></component>
          </keep-alive>
          <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.path"></component>
        </router-view>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
</template>

<script steup>
  import Header from '@c/Header'
  import Footer from '@c/Footer'
  import {onMounted} from 'vue'

  export default {
    name: 'MainLayout',
    components: {
      Header,
      Footer,
    },
    setup() {
      onMounted( () => {
        
      } )




    }
  }
</script>

<style scoped lang='scss'>
  .el-container {
    background-color: #fff;
    min-height: 100vh;
    min-width: $minWidthPx;
  }

  .el-header {
   padding: 0;
   margin: 0;
  }

  .el-main {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .el-footer {
    margin: 0;
    padding: 0;
    width: 100%;
  }
</style>