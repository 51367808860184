import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/layout/MainLayout";


const routes = [
  {
    path: "/",
    name: "Home",
    component: MainLayout,
    children: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: "/aboutus",
        component: () => import("@pages/aboutUS/AboutUS"),
        meta: {
          title: "关于我们",
          keepAlive:true,
        },
      },
      {
        path: "/home",
        component: () => import("@pages/home/Home"),
        meta: {
          title: "新浪小贷",
          keepAlive:true,
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        redirect: '/home'
      }
    ],
  },
];


const router = createRouter({
  history:createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  document.title = "新浪小贷"
})
export default router