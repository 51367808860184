<!--  -->
<template>
  <div class='Footer'>
    <div class="divBanner">
      <div class="telView">
        <p>投诉电话：0794-8207466</p>
        <p>如您有投诉需求，可拨打电话联系我们</p>
      </div>
    </div>

    <div class="footerBlackView">
      <img :src="require('../assets/FooterSinaMiCreditLogo@2x.png')" alt="">
      <div class="textView">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36100202000327" target="#">
          <img :src="require('../assets/footerPoliceLogo.png')">
          公安备案号：赣公网安备36100202000327号
        </a>
        <p> 
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="#">
          ICP备案/许可证号：赣ICP备2021001807号-2
          </a>
        </p>
        <p>公司主体：抚州市新浪网络小额贷款有限公司 &nbsp;&nbsp;&nbsp;&nbsp; 投诉电话：0794-8207466</p>
      </div>

    </div>


  </div>
</template>

<script steup>
  export default {
    name: 'Footer',
  }
</script>

<style scoped lang='scss'>
  .Footer {
    padding: 0;
    margin: 0;
    width: 100%;

  }

  .divBanner {
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-image: url('../assets/FooterBanner@2x.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vw * 0.21875;
    position: relative;

    .telView {
      padding: 0;
      
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translate(0%, -50%);
      align-content: center;
      color: #E5C88A;
      @include adjustFontScreenFit(24);
      // font-size: 1.5vw;
      // @media screen and (min-width:1920px) {
      //   font-size: 24px;
      // }

      // @media screen and (max-width:400px) {
      //   font-size: 6px;
      // }

      
    }
  }

  .footerBlackView {
    background-color: #212121;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 20px 0;
    

    img {
      margin-left: 10%;
      width: 7.8vw;
      max-width: 152px;
    }
  }

  .textView {
    margin-right:17.5%;
    color: #A2A3A5;
    text-align: right;

    @include adjustFontScreenFit(15,2.5);

    a {
      img {
        width: 0.78vw;
        max-width: 16px;
        padding: 1.5px 0;
        vertical-align: top;
      }
      color: #A2A3A5;
      text-decoration: none;

    }
    // font-size: 1.0vw;
    // @media screen and (min-width:1920px) {
    //   font-size: 12px;
    // }
    // @media screen and (max-width:400px) {
    //   font-size: 6px;
    // }
  }
</style>